<template>
  <!-- 表单管理 -->
  <div class="formment-box">
    <div class="box">
      <div class="top-nav">
        <div class="title">表单管理</div>
        <div>
          <el-button type="primary" size="medium" @click="addmanagement">
            <i class="el-icon-plus fontweight9"></i
            ><span class="fontweight4">添加管理</span>
          </el-button>
        </div>
      </div>
      <div class="search-box">
        <div class="search-input">
          <el-input
            v-model="search"
            placeholder="请输入表单名称搜索"
          ></el-input>
          <i class="el-icon-search"></i>
        </div>
        <el-button @click="searchClick">搜索</el-button>
      </div>
      <div>
        <infotable
          :tableHeadConfig="formMenttitle"
          :selection="false"
          align="left"
          :tableLoadData="tablelist"
        >
          <template v-slot:operation="slotData">
            <div class="oprtion-style">
              <div class="oper-modify" @click="editclick(slotData.data.row)">
                编辑
              </div>
              <div class="border-box"></div>
              <div class="oper-del" @click="delclick(slotData.data.row)">
                删除
              </div>
            </div>
          </template>
        </infotable>
      </div>
      <div class="bottom-pagination">
        <bottom-pagination @pageclcik="pageclcik" :pagination="pagination"></bottom-pagination>
      </div>
      <div v-show="addmanagementflag" class="formpopup">
        <formpopup @calcelclick="calcelclick" @btnclick="btnclick"></formpopup>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { sessionGetKey } from "@/utils/sessionStorage.js";
import pagination from "@/components/pagination/pagination.vue";
import infotable from "@/components/infoTable/infoTable.vue";
import formpopup from "./components/popup/formpopup.vue";
export default {
  data() {
    return {
      addmanagementflag: false,
      tablelist: [],
      pagination:{},
      page:1,
      formMenttitle: [
        {
          label: "ID",
          field: "id",
          sort: true,
        },
        {
          label: "表单名称",
          field: "name",
        },
        {
          label: "状态更新时间",
          field: "updated_at",
        },
        {
          label: "操作",
          columnType: "slot",
          slotName: "operation",
        },
      ],
      search: "",
    };
  },
  components: {
    infotable,
    "bottom-pagination": pagination,
    formpopup,
  },
  mounted() {
    this.getlist();
  },
  
  methods: {
      ...mapMutations(['settingForm','createlistStroe']),
    editclick(e) {
      this.settingForm(e)
      this.$router.push({
        name: "editform",
      });
      console.log(e);
    },
    btnclick(e) {
      let that = this;
      const platform_id = sessionGetKey("platformId");
      this.$request({
        url: this.$api.formApi.addform + `?platform_id=${platform_id}`,
        method: "post",
        data: {
          name: e.name,
          text: "[]",
        },
      })
        .then((res) => {
          if (res.code == 0) {
            that.$message({
              message: "添加成功",
              type: "success",
            });
            that.getlist();
            that.addmanagementflag = false;
          } else {
            that.addmanagementflag = false;
          }
          console.log(res);
        })
        .catch((err) => {
          that.addmanagementflag = false;
          that.$message.error("添加失败");
          console.log(err);
        });

      console.log(e);
    },
    calcelclick() {
      this.addmanagementflag = false;
    },
    addmanagement() {
      this.addmanagementflag = true;
    },
    searchClick() {
      let that=this
 const platform_id = sessionGetKey("platformId");
       this.$request({
        url: this.$api.formApi.getform + `?platform_id=${platform_id}`,
        method: "post",
        data: {
          name:that.search
        },
      })
        .then((res) => {
          if(res.code==0){
  that.tablelist = res.data.data;
  this.search=''
          }
        })
    },
    pageclcik(e) {
      this.page=e
      console.log(e, "page");
      this.getlist()
    },
    delclick(e) {
      let that = this;
      const platform_id = sessionGetKey("platformId");
 console.log(e);

      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
        
  that
        .$request({
          url: that.$api.formApi.delform + `?platform_id=${platform_id}`,
          method: "post",
          data: {
            id: e.id,
          },
        })
        .then((res) => {
          if (res.code == 0) {
            that.getlist();
          } else {
            that.$message.error("删除数据失败");
          }
          console.log(res);
        })
        .catch((err) => {
          that.$message.error("数据失败");
          console.log(err);
        });

        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });          
        });
    
     
      //
    },
    getlist() {
      let that = this;
      const platform_id = sessionGetKey("platformId");
      this.$request({
        url: this.$api.formApi.getform + `?platform_id=${platform_id}`,
        method: "post",
        data: {
          page:that.page
        },
      })
        .then((res) => {
          if (res.code == 0) {
            that.pagination={
               currentpage: res.data.current_page,
          total: res.data.total,
          totalpage: res.data.last_page,
          pageSize: res.data.per_page,
            }
            that.tablelist = res.data.data;
          } else {
            that.$message.error("获取数据失败");
          }
          console.log(res);
        })
        .catch((err) => {
          that.$message.error("获取数据失败");
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.formpopup {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba($color: #000000, $alpha: 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}
.fontweight9 {
  font-weight: 900;
}
.fontweight4 {
  font-weight: 400;
  margin-left: 3px !important;
}
.formment-box {
  height: 100%;
  overflow-y: auto;
  padding: 66px;
  min-height: 650px;
  .border-box {
    width: 1px;
    height: 18px;
    background: #4458fe;
    margin: 0 10px;
  }
  .oprtion-style {
    display: flex;
    align-items: center;
  }
  .oper-modify {
    color: #4458fe;
  }
  .oper-del {
    color: #4458fe;
  }
  .box {
    overflow: hidden;
    position: relative;
     border-radius: 16px;
    width: 100%;
    // height: 100%;
    min-height: 100%;
    background: #fff;
    padding: 33px;
    min-width: 1200px;
    .top-nav {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .title {
            font-weight: 600;
        padding-bottom: 10px;
        color: #4458fe;
        font-size: 20px;
        border-bottom: #4458fe 2px solid;
      }
    }

    .search-box {
      margin: 21px 0;
      display: flex;
      align-items: center;
      .search-input {
        margin-right: 20px;
        padding: 0 15px;
        border-radius: 6px;
        width: 400px;
        box-sizing: border-box;
        border: 1px solid #999;
        display: flex;
        align-items: center;
        /deep/.el-input__inner {
          border: 0 !important;
          padding: 0 !important;
        }
        .el-icon-search {
          font-size: 20px;
          color: #999;
        }
      }
      .el-button {
        border: #4458fe 1px solid;
        background-color: #4458fe !important;
        color: #ffffff;
      }
    }
  }
  .bottom-pagination {
    // position: absolute;
    // left: 0;
    // right: 0;
    // bottom: 30px;
    margin-top:20px;
  }
}
</style>