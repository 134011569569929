<template>
  <div class="formpopup-box">
      <i class="el-icon-close" @click="calcel"></i>
    <div class="title">添加表单</div>
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="表单名称" class="from-name">
        <el-input v-model="form.name" placeholder="请输入表单名称"></el-input>
      </el-form-item>
      <!-- <el-form-item label="活动定时结束">
        <el-switch v-model="form.flag"></el-switch>
      </el-form-item> -->
      <!-- <el-form-item label="总共可提交">
        <el-input-number
          v-model="form.num"
          @change="handleChange"
          :min="1"
          :max="10"
          label="描述文字"
        ></el-input-number>
      </el-form-item> -->
      <el-form-item label="">
        <el-button class="cancel" @click="calcel">取消</el-button>
        <el-button class="btn" @click="savebtn">确定</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        name: "",
        num: "",
        flag: false,
      },
    };
  },
  methods: {
    handleChange() {},
    calcel() {
      this.form = {
        name: "",
        num: "",
        time: "",
      };
       this.$emit("calcelclick");
    },
    savebtn() {
      this.$emit("btnclick", this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
.formpopup-box {
    position: absolute;
  background-color: #fff;
  width: 640px;
  padding: 20px 40px;
  border-radius: 10px;
  .el-icon-close{
      font-size: 22px;
      font-weight: 900;
      position: absolute;
      right: 30px;
  }
  .cancel {
    background-color: #f5f6fa;
    color: #999999;
    border: 0;
    width: 120px;
  }
  .btn {
    background-color: #4458fe;
    color: #fff;
    border: 0;
    width: 120px;
  }
  .title {
    height: 40px;
    line-height: 40px;
  }
  .from-name {
    /deep/.el-input__inner {
      height: 35px;
      line-height: 35px;
    }
  }
  .el-form-item {
    margin-bottom: 10px;
    /deep/.el-input__inner {
      height: 35px;
      box-sizing: border-box;
      line-height: 35px;
    }
    /deep/.el-input-number {
      .el-input-number__increase {
        box-sizing: border-box;
        height: 35px;
        line-height: 35px;
      }
      .el-input-number__decrease {
        height: 35px;
        line-height: 35px;
        box-sizing: border-box;
      }
    }
  }
}
</style>